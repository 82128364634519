

import Autenticadas from './autenticados.routes'
import NaoAutenticadas from './naoAutenticados.routes'


export default function RotasIndex(){
    const autenticado = false
    return(
        autenticado === true ? <Autenticadas /> : <NaoAutenticadas />        
    )
}