import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Inicio from '../Inicio'
import CadastrosGuiasGratuitos from '../CadastroGuiasGratuitos'


export default function Rotas() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Inicio />} />
                <Route path='/CadastrosGuiasGratuito' element={ <CadastrosGuiasGratuitos /> } />
                <Route path='*' element={ <Inicio /> } />
            </Routes>
        </BrowserRouter>
    )
}