import React, { useState, useEffect } from 'react'
import './estilo.cadastrosguiasgratuitos.scss'
import Banner from '../assets/logo_transparente.png'
import CapaImagen from '../assets/CapaGuiaGratuitoCanteiros.jpg'
import { apiLocal, apiIbge } from '../Api/Local/apiLocal'
import { toast } from 'react-toastify'
import { IMaskInput } from 'react-imask'
import { useNavigate } from 'react-router-dom'

export default function CadastrosGuiasGratuitos() {

    const navigation = useNavigate()

    const [puxarPerfil, setPuxarPerfil] = useState([''])
    const [perfilCliente, setPerfilCliente] = useState([''])
    const [estados, setEstados] = useState([''])
    const [estadoSigla, setEstadoSigla] = useState('')
    const [cidades, setCidades] = useState([''])

    const [nomeCompleto, setNomeCompleto] = useState('')
    const [email, setEmail] = useState('')
    const [numeroTelefoneMask, setNumeroTelefoneMask] = useState('')
    const [cidade, setCidade] = useState('')

    //console.log(puxarPerfil)


    useEffect(() => {
        async function pegarPerfil() {
            const resposta = await apiLocal.get('/LeituraPerfilGeral')
            setPuxarPerfil(resposta.data)
        }
        // eslint-disable-next-line
        pegarPerfil()
    }, [])

    useEffect(() => {
        async function pegarEstadosIbge() {
            const resposta = await apiIbge.get('/estados?orderBy=nome')
            setEstados(resposta.data)
        }
        // eslint-disable-next-line
        pegarEstadosIbge()
    }, [])

    async function handleCadastrarCliente(e) {
        e.preventDefault()
        if (!nomeCompleto || !email || !numeroTelefoneMask || !perfilCliente || !estadoSigla || !cidade) {
            toast.warn('Existem campos em Branco', {
                toastId: 'ToastId'
            })
            return
        }
        const str = numeroTelefoneMask
        const numeroTelefone = str.replace(/[^0-9]/g, '')
        const perfil = perfilCliente
        const estado = estadoSigla
        const aceito = false
        const enviado = false
        try {
            await apiLocal.post('/CadGuiaGratis', {
                nomeCompleto,
                email,
                numeroTelefone,
                perfil,
                estado,
                cidade,
                aceito,
                enviado
            })
            toast.success('Em breve receberá um e-mail com o link para download', {
                toastId: 'ToastId'
            })            
            navigation('/')
           // window.location.reload()
        } catch (err) {
            toast.error(err.response.data.error, {
                toastId: 'toastId'
            })
        }
    }

    async function handleEstadoSigla() {
        const resposta = await apiIbge.get(`/estados/${estadoSigla}/distritos?orderBy=nome`)
        setCidades(resposta.data)
    }

    return (
        <div className='conteinerCadastrosGuiasGratuitos'>
            <header>
                <img src={Banner} alt='Logo' />
            </header>
            <div className='containerDados'>
                <div className='conteinerEsquerda'>
                    <p>
                        Neste guia você encontrará informações <br /> relevantes e descomplicadas sobre  Canteiros. <br />Preencha o formulário para receber por e-mail o Guia em PDF!
                    </p>
                    <img src={CapaImagen} alt='' />

                </div>
                <div className='conteinerDireita'>
                    <form onSubmit={handleCadastrarCliente}>
                        <h1>Formulário de Cadastro</h1>
                        <input
                            type='text'
                            placeholder='Digite seu Nome Completo*'
                            value={nomeCompleto}
                            onChange={(e) => setNomeCompleto(e.target.value)}
                        />
                        <input
                            type='email'
                            placeholder='Digite seu E-mail*'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <IMaskInput
                            type='text'
                            mask='(00) 00000-0000'
                            placeholder='Digite seu Celular/WhatsApp*'
                            value={numeroTelefoneMask}
                            onChange={(e) => setNumeroTelefoneMask(e.target.value)}
                        />
                        <select
                            value={perfilCliente}
                            onChange={(e) => setPerfilCliente(e.target.value)}
                        >
                            <option value='' key=''>Selecione o Perfil*</option>
                            {puxarPerfil.map((item) => {
                                return(
                                    <option value={item.nomePerfil} key={item.id}>{item.nomePerfil}</option>
                                )
                            })}

                        </select>
                        <select
                            value={estadoSigla}
                            onChange={(e) => setEstadoSigla(e.target.value)}
                            onBlur={handleEstadoSigla}
                        >
                            <option value='' key=''>Selecione o Estado*</option>
                            {estados.map((item) => {
                                return (
                                    <option value={item.sigla} key={item.id}>{item.nome}</option>
                                )
                            })}
                        </select>
                        <select
                            value={cidade}
                            onChange={(e) => setCidade(e.target.value)}
                        >
                            <option value='' key=''>Selecione a Cidade*</option>
                            {cidades.map((item) => {
                                return (
                                    <option value={item.nome} key={item.id}>{item.nome}</option>
                                )
                            })}
                        </select>
                        <button type='submit'>Quero Receber</button>
                        <p>Campos com * são obrigatórios</p>
                        <p>**Todos os dados colhidos seguem rigorasamente o padrão da LGPD</p>
                    </form>
                </div>
            </div>
        </div>
    )
}