import RotasIndex from './Routes'

import { ToastContainer } from 'react-toastify'

export default function App() {
  return (
    <div className='container-fluid'>
      <RotasIndex />
      <ToastContainer
        autoClose={5000}
      />

    </div>
  )
}
