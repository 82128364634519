import logo from '../assets/logo.png'
import './estilo.inicio.scss'
import Facebook from '../assets/facebook.png'
import Instagram from '../assets/instagram.png'
import { Link } from 'react-router-dom'

export default function Inicio() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Contatos:
          <br />
          Email: contato@verdevidacultivares.agr.br <br />
          Celular:  (14) 98138-4515 <br />
          WhatsApp: (14)  3208-0753 <br />
          <Link to='https://web.facebook.com/verdevidacultivares' target='_blank'>
            <img src={Facebook} alt="" />
          </Link>
          <Link to='https://www.instagram.com/verdevidacultivares/' target='_blank'>
            <img src={Instagram} alt="" />
          </Link>
        </p>
      </header>
    </div>
  );
}


