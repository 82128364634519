import axios from 'axios'

const apiLocal = axios.create({
    //baseURL: 'http://localhost:3333'
    //baseURL: 'http://10.152.20.64:3333'
    baseURL: 'https://mss.eti.br:21093'
    
})

export { apiLocal }

const apiIbge = axios.create({
    baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades/'
})

export { apiIbge }